.pastWord {
  color: #ddddff;
  font-weight: bold;
  text-shadow: -1px 0 black, 0 2px black, 2px 0 black, 0 -1px black;
  padding: 0.2em;
  border-radius: 0.4em;
}

.got {
  background-color: #44ff44;
}

.missed {
  background-color: #ff4444;
}