.keyboard {
  margin-top: 1em;
}

.keyboardLetter {
  margin: 0.2em;
  font-weight: bold;
  text-shadow: -1px 0 black, 0 2px black, 2px 0 black, 0 -1px black;
}

button.keyboard-color-unknown {
  background: #8888ff;
}

button.keyboard-color-nomatch {
  background: #ff8888;
}

button.keyboard-color-partmatch {
  background: #ffff88;
}

button.keyboard-color-match {
  background: #88ff88;
}