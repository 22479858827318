.gridrow-letter {
  background: #8888ff;
  font-size: 2em;
  width: 2em;
  height: 2em;
}

.gridrow-letter-sm {
  background: #8888ff;
  font-size: 1em;
  width: 1.5em;
  height: 1.5em;
}

.gridrow-color-unknown {
  background: #8888ff;
}

.gridrow-color-nomatch {
  background: #ff8888;
}

.gridrow-color-partmatch {
  background: #ffff88;
}

.gridrow-color-match {
  background: #88ff88;
}

.gridrow-color-broken {
  background: #888888;
  background-image: url(../../../public/crackedScreen.png);
  background-size: 100% 100%;
}