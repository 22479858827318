.WelcomePage {
  background-color: #282c34;
}

.fullScreen {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#roomCodeInput {
  color: #ffffff;
  font-weight: bold;
  width: 8em;
  text-align: center;
}