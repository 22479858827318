.GamePage {
  background-color: #282c34;
}

.fullScreen {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#wordInput {
  color: #ffbbbb;
  font-size: 2em;
}