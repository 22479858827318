.fullScreen {
  height: 100%;
  width: 100%;
}

.VictoryPage {
  background-color: #282c34;
}

h1.bright {
  color: #ddddff;
}

.bright {
  color: #ddddff;
}
