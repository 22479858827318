iframe {
  z-index: -1;
}

.clickShield {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.fullScreen {
  height: 100%;
  width: 100%;
}

.WaitingPage {
  background-color: #282c34;
}

h1.bright {
  color: #ffbbbb;
}